import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';
import { fDate } from '../../../utils/formatTime';

const BranchesTable = ({ rows }) => {
  const columns = ['Location', 'Founded Date', 'Is Active'];
  return (
    <TableContainer sx={{ minWidth: 400 }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell key={i}>{col}</TableCell>
            ))}

            {/* <TableCell>
              
          </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.subLocation}</TableCell>
                <TableCell>{fDate(row.foundedDate)}</TableCell>
                <TableCell>
                  <Checkbox checked={row.isActive} disabled />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BranchesTable.propTypes = {
  //   columns: PropTypes.object,
  rows: PropTypes.array,
};

export default BranchesTable;
