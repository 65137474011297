// routes
import { Toaster } from 'react-hot-toast';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { AuthProvider } from './context/auth';
import { GlobalProvider } from './context/global';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <GlobalProvider>
      <AuthProvider>
        <ThemeProvider>
          <Toaster
            toastOptions={{
              position: 'bottom-right',
              duration: 2000,
            }}
          />
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      </AuthProvider>
    </GlobalProvider>
  );
}
