const _storeData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const storeData = _storeData;

const _getStoredData = (key) => {
  return JSON.parse(localStorage.getItem(key) || null);
};

export const getStoredData = _getStoredData;
