import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';

const GeneralInformation = () => {
  const {
    kycConfig: config,
    businessType,
    setBusinessType,
    generalData: gd,
    saveGeneralData,
    kycSectionsStatus,
  } = useOutletContext();
  const sectionStatus = kycSectionsStatus.general_information;
  const { authData, authRequest, fileRequest } = useAuth();
  const [generalData, setGeneralData] = useState({
    userName: '',
    phoneNumber: '',
    name: '',
    businessType: '',
    industryType: '',
    website_url: '',
    businessName: '',
  });

  useEffect(() => {
    setGeneralData({ ...gd });
  }, [gd]);

  //   console.log('Props', config, businessType, setBusinessType);

  const kycConfig = config || {};

  useEffect(() => {
    // alert("General Loaded");
    // if (!generalData.name) {
    // }
  }, []);

  const updateGeneralData = (key, value) => {
    const newData = { ...generalData };
    newData[key] = value;
    // console.log(key, value, newData);
    setGeneralData(newData);
    // if (key === 'businessType') {
    //   setBusinessType(value);
    // }
  };

  const getGeneralData = () => authRequest();

  const getFile = () => {
    fileRequest(
      `business/file?url=${encodeURIComponent(
        'https://caset-files.s3.ap-south-1.amazonaws.com/dev/business/4d5b67ea-aa8f-4df2-b491-289e4b8ba50c/kyc/INDIVIDUAL_PAN_FILE/AWSomeDayOnline_Q222_1-71da5f74.pdf'
      )}`
    )
      .then((blob) => URL.createObjectURL(blob))
      .then((href) => {
        Object.assign(document.createElement('a'), {
          href,
          download: 'AWSomeDayOnline_Q222_1-71da5f74.pdf',
        }).click();
      });
    // .then((url) => {
    //   window.open(url, '_blank');
    //   URL.revokeObjectURL(url);
    // });
  };

  const user = (authData && authData?.user) || {};

  if (!user.phoneNumber) {
    return <Page title="General Information" />;
  }

  return (
    <Page title="General Information">
      <Container>
        <Stack spacing={1} paddingTop={2}>
          <Typography variant="h3">General Information</Typography>
          <Typography>We need a few more details about you and your business to give you the best service</Typography>
        </Stack>

        {sectionStatus && sectionStatus.status === 'rejected' ? (
          <Stack>
            <Typography color={'red'}>{sectionStatus.reason}</Typography>
          </Stack>
        ) : null}
        <Stack spacing={2} paddingTop={2}>
          {/* <Typography variant="h5">Personal Information</Typography> */}
          <Card>
            <Stack padding={2} spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl fullWidth>
                  <Typography>Country of Operation</Typography>

                  {/* <InputLabel id="demo-simple-select-label">Country of Operation</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    disabled
                    defaultValue={'india'}
                    // label={'Country of Operation'}
                  >
                    <MenuItem value={'india'}>India</MenuItem>
                  </Select>
                </FormControl>
                {/* <TextField fullWidth label={'First Name'} value={user.firstName} /> */}
                {/* <TextField fullWidth label={'Last Name'} value={user.lastName} /> */}
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Email</Typography>
                  <TextField fullWidth disabled value={user.email} />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Phone Number</Typography>
                  <TextField fullWidth disabled value={user.phoneNumber} />
                </Box>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'column', lg: 'column', xl: 'row' }} spacing={2}>
                <Box flexGrow={1}>
                  <FormControl fullWidth>
                    <Typography>Business Type</Typography>
                    <Select
                      value={generalData.businessType}
                      onChange={(e) => {
                        updateGeneralData('businessType', e.target.value);
                      }}
                    >
                      {(kycConfig.businessTypeOptions || []).map((v, i) => (
                        <MenuItem key={v.key || i} value={v.key}>
                          {v.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box flexGrow={1}>
                  <FormControl fullWidth>
                    <Typography>Industry Type</Typography>
                    <Select
                      value={generalData.industryType}
                      onChange={(e) => {
                        console.log(e);
                        updateGeneralData('industryType', e.target.value);
                      }}
                    >
                      {(kycConfig.industryTypeOptions || []).map((v, i) => (
                        <MenuItem key={v.key || i} value={v.key}>
                          {v.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box flexGrow={1}>
                  <Typography>Your Business Name</Typography>
                  <TextField value={generalData.businessName} disabled fullWidth />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Website URL/Application Link</Typography>
                  <TextField
                    fullWidth
                    value={generalData.website_url}
                    onChange={(e) => {
                      updateGeneralData('website_url', e.target.value);
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
          </Card>

          <Button
            variant={'contained'}
            onClick={() => {
              saveGeneralData(generalData)
                .then(() => {
                  toast.success('Saved Successfully');
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            }}
          >
            Save & Continue
          </Button>
          {/* <Button variant={'contained'} onClick={getFile}>
            {' '}
            Get file
          </Button> */}
        </Stack>
      </Container>
    </Page>
  );
};

export default GeneralInformation;
