import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Grid,
} from '@mui/material';
import { fDate, fDateTime } from '../../../utils/formatTime';
import AppWidgetSummary from './AppWidgetSummary';

const PreSaleRevenue = ({ data }) => {
  const salesRevenue = data || {};
  const columns = ['', 'Listed', 'Sold'];
  const blue = '#2f85a7';
  const lightGreenBg = '#67ea90';
  const green = '#1b6f35';
  return (
    <TableContainer sx={{ minWidth: 400 }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell align={col ? 'center' : 'inherit'} key={i}>
                <Typography variant="h6" align="center">
                  {col}
                </Typography>
              </TableCell>
            ))}

            {/* <TableCell>
              
          </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Grid item>Revenue</Grid>
            </TableCell>
            <TableCell align="center" style={{ color: blue }}>
              <Grid>
                <AppWidgetSummary
                  title={`${salesRevenue.percent}% of estimated revenue`}
                  sx={{ minWidth: 200 }}
                  total={salesRevenue.totalAmount || 0}
                  // totalSuffix={'10 lakhs'}
                  color="info"
                  icon={'ant-design:apple-filled'}
                />
              </Grid>
            </TableCell>
            <TableCell align="center" style={{ color: green }}>
              <AppWidgetSummary
                title={`${salesRevenue.percentSold}% of estimated revenue`}
                sx={{ minWidth: 200, color: green, backgroundColor: lightGreenBg }}
                total={salesRevenue.amountSold || 0}
                // totalSuffix={'6.5 lakhs'}
                color="info"
                icon={'ant-design:apple-filled'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Grid item>Casets</Grid>
            </TableCell>
            <TableCell align="center" style={{ color: blue }}>
              <Grid>
                <AppWidgetSummary
                  title=""
                  sx={{ minWidth: 200 }}
                  total={salesRevenue.quantity || 0}
                  // totalSuffix={'1000'}
                  color="info"
                  icon={'ant-design:apple-filled'}
                />
              </Grid>
            </TableCell>
            <TableCell align="center" style={{ color: green }}>
              <AppWidgetSummary
                title=""
                sx={{ minWidth: 200, color: green, backgroundColor: lightGreenBg }}
                total={salesRevenue.quantitySold || 0}
                // totalSuffix={'650'}
                color="info"
                icon={'ant-design:apple-filled'}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PreSaleRevenue.propTypes = {
  //   columns: PropTypes.object,
  rows: PropTypes.array,
};

export default PreSaleRevenue;
