import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import toast from 'react-hot-toast';
import { useAuth } from '../../../context/auth';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const { authRequest, updateUserData } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    rePassword: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .oneOf([Yup.ref('password'), null], 'Does not match with password!'),
    //   .when('password', (password, schema) =>Yup.required('Password is required')
    //     .matches(
    //       password,
    //       'Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    //     )),
  });

  const getMe = () =>
    authRequest('business/user/me', 'GET')
      .then((data) => {
        console.log(data);
        updateUserData(data);
        // navigate('/dashboard/app', { replace: true });
        // toast.success('Password reset done!');
      })
      .catch((err) => {
        console.log(err);
      });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      rePassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      // navigate('/dashboard', { replace: true });
      formik.setSubmitting(false);
      authRequest('business/user/reset_password', 'POST', values)
        .then(async (data) => {
          console.log(data);
          await getMe();
          navigate('/dashboard/app', { replace: true });

          toast.success('Password reset done!');
        })
        .catch((err) => {
          formik.setSubmitting(false);
          toast.error(err.message || 'Error occurred');
          console.log(err);
        });
      // setTimeout(() => {
      //   formik.setSubmitting(false);
      // }, 2000);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} marginBottom={3}>
          <TextField
            fullWidth
            autoComplete="old-password"
            type={showPassword ? 'text' : 'password'}
            label="Old Password"
            {...getFieldProps('oldPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="re-new-password"
            type={showPassword ? 'text' : 'password'}
            label="ReEnter New Password"
            {...getFieldProps('rePassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.rePassword && errors.rePassword)}
            helperText={touched.rePassword && errors.rePassword}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
