import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import toast from 'react-hot-toast';
import Label from '../../components/Label';
import { useAuth } from '../../context/auth';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import FileInput from '../../components/FileInput';
import Loader from '../../components/Loader';

const KYC = () => {
  const navigate = useNavigate();

  const {
    kycConfig: config,
    businessType,
    kycInfoData: kd,
    getKycInfoData,
    saveKycInfoData,
    kycSectionsStatus,
  } = useOutletContext();
  const { authData, setKycVerified, formAuthRequest, fileRequest } = useAuth();
  const user = (authData && authData?.user) || {};
  const [isLoading, setIsLoading] = useState(false);

  const [kycInfoData, setKycInfoData] = useState({});
  const sectionStatus = kycSectionsStatus.kyc_information;

  useEffect(() => {
    setKycInfoData(kd);
    console.log('Sections data', kd.sectionStatus);
  }, [kd]);

  useEffect(() => {
    getKycInfoData();
  }, []);

  const kycConfig = config?.kycConf || {};
  console.log(kycConfig, businessType);

  const handleFileUpload = (formData) => {
    setIsLoading(true);
    formAuthRequest('business/merchant/kyc/file', 'POST', formData)
      .then(() => {
        setIsLoading(false);
        getKycInfoData();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleFileDownload = (url, fileName) => {
    setIsLoading(true);
    fileRequest(`business/file?url=${encodeURIComponent(url)}`)
      .then((blob) => {
        setIsLoading(false);
        return URL.createObjectURL(blob);
      })
      .then((href) => {
        Object.assign(document.createElement('a'), {
          href,
          download: fileName,
        }).click();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleKycFieldChange = (k, v) => {
    setKycInfoData({
      ...kycInfoData,
      [k]: v,
    });
  };

  const renderRow = (row) => (
    <Box flexGrow={1}>
      <Typography>
        {row.title} {row.optional ? <Label>Optional</Label> : null}{' '}
      </Typography>
      <Box flexDirection={'row'} display="flex" spacing={2} paddingTop={1} flexWrap="wrap" rowGap={2}>
        {row.fields &&
          row.fields.map((f) => {
            // console.log(f);
            const keyData = kycInfoData[f.key];
            if (f.type === 'file') {
              return (
                <FileInput
                  key={f.key}
                  fileData={keyData}
                  fileKey={f.key}
                  onChange={(file) => {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('key', f.key);
                    handleFileUpload(data);
                  }}
                  handleFileDownload={() => {
                    handleFileDownload(keyData.fileUrl, keyData.originalName);
                  }}
                />
              );
            }
            if (f.type === 'select') {
              console.log(f.options);
              return (
                <Select
                  value={kycInfoData[f.key] || ''}
                  onChange={(e) => {
                    handleKycFieldChange(f.key, e.target.value);
                  }}
                  sx={{
                    // flexGrow: 1,
                    minWidth: 200,
                    maxWidth: 500,
                  }}
                >
                  {(f.options || []).map((v, i) => (
                    <MenuItem key={v.key || i} value={v.key}>
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }
            return (
              <OutlinedInput
                key={f.key}
                value={kycInfoData[f.key] || ''}
                placeholder={f.placeholder}
                fullWidth
                onChange={(e) => {
                  handleKycFieldChange(f.key, e.target.value);
                }}
                sx={{
                  // flexGrow: 1,
                  minWidth: 200,
                  maxWidth: 500,
                }}
                endAdornment={
                  f.infoText ? (
                    <InputAdornment position="end">
                      <Iconify icon={'eva:info-fill'} width={16} height={16} />
                    </InputAdornment>
                  ) : undefined
                }
              />
            );
          })}
      </Box>
    </Box>
  );
  return (
    <Page title="KYC">
      <Loader loading={isLoading} />
      <Container>
        <Stack spacing={2} paddingTop={2}>
          <Stack spacing={1}>
            <Typography variant="h3">KYC Information for Partnership</Typography>
            <Typography>
              We need the following documents to verify your account. (File formats supported: jpeg, jpg, png and pdf).
            </Typography>
          </Stack>
          {sectionStatus && sectionStatus.status === 'rejected' ? (
            <Stack>
              <Typography color={'red'}>{sectionStatus.reason}</Typography>
            </Stack>
          ) : null}
          {businessType ? (
            <>
              <Card>
                <Stack padding={2} spacing={2}>
                  {kycConfig && kycConfig[businessType] && kycConfig[businessType].map((r) => renderRow(r))}
                </Stack>
              </Card>
              <Button
                variant="contained"
                onClick={() => {
                  // setKycVerified();
                  // setTimeout(() => {
                  //   navigate('/dashboard/app', { replace: true });
                  // }, 200);
                  const sendData = {};
                  Object.keys(kycInfoData).forEach((k) => {
                    if (typeof kycInfoData[k] === 'string') {
                      sendData[k] = kycInfoData[k];
                    }
                  });

                  saveKycInfoData(sendData);
                }}
              >
                Submit for review
              </Button>
            </>
          ) : (
            <Card>
              <Stack padding={2} spacing={2}>
                <Typography>Please select business type</Typography>
              </Stack>
            </Card>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default KYC;
