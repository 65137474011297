import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import KYCSubmitted from '../../pages/kyc/Submitted';
import Loader from '../../components/Loader';
import { useGlobal } from '../../context/global';
import { useAuth } from '../../context/auth';
import KYCSidebar from './KYCSidebar';
import KYCNavbar from './KycNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function KYCLayout() {
  const navigate = useNavigate();
  const { authRequest, updateUserData, authData } = useAuth();

  const business = authData?.user?.businessUser?.business || {};

  const [open, setOpen] = useState(false);
  const [businessType, setBusinessType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [generalData, setGeneralData] = useState({
    businessType: '',
    industryType: '',
    website_url: null,
    businessName: '',
  });

  const [bankData, setBankData] = useState({
    upiAddress: null,
    bankAccount: null,
    ifsc: null,
    accountHolderName: '',
  });

  const [addressData, setAddressData] = useState({
    address: {
      address: '',
      pincode: '',
      city: '',
      state: '',
    },
    gstAddress: {
      address: '',
      pincode: '',
      city: '',
      state: '',
    },
    isGstAddressSame: false,
  });

  const [kycInfoData, setKycInfoData] = useState({});

  //   const {} = useGlobal();

  const [kycConfig, setKycConfig] = useState({});

  const [kycSectionsStatus, setKycSectionStatus] = useState({});

  useEffect(() => {
    // setTimeout(() => {
    getKycConfig();
    setIsLoading(true);
    Promise.all([getCurrentUserData(), getGeneralData(), getAddressData(), getBankData(), getKycInfoData()])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // }, 200);
  }, []);

  useEffect(() => {
    setBusinessType(generalData.businessType || '');
  }, [generalData]);

  const getCurrentUserData = () => {
    authRequest('business/user/me', 'GET', {})
      .then((data) => {
        console.log(data);
        updateUserData(data);
      })
      .catch((err) => {});
  };

  const getGeneralData = () =>
    authRequest('business/merchant/kyc/general', 'GET', {})
      .then((data) => {
        console.log(data);
        setGeneralData(data.data);
        if (!businessType) {
          setBusinessType(data.data.businessType || '');
        }
      })
      .catch((err) => {});

  const saveGeneralData = (data) =>
    authRequest('business/merchant/kyc/general', 'PATCH', data).then((data) => getGeneralData());

  const getBankData = () =>
    authRequest('business/merchant/kyc/bank', 'GET', {})
      .then((data) => {
        console.log(data);
        setBankData(data.data);
      })
      .catch((err) => {});

  const saveBankData = (data) => authRequest('business/merchant/kyc/bank', 'PATCH', data).then((data) => getBankData());

  const getAddressData = () =>
    authRequest('business/merchant/kyc/address', 'GET', {})
      .then((data) => {
        console.log(data);
        setAddressData(data);
      })
      .catch((err) => {});

  const saveAddressData = (data) =>
    authRequest('business/merchant/kyc/address', 'PATCH', data).then((data) => getAddressData());

  const getKycInfoData = () =>
    authRequest('business/merchant/kyc/info', 'GET', {})
      .then((data) => {
        console.log(data);
        setKycInfoData(data);
        setKycSectionStatus(data.sectionStatus || {});
      })
      .catch((err) => {});

  const saveKycInfoData = (data) =>
    authRequest('business/merchant/kyc/info', 'PATCH', data).then((data) => {
      getKycInfoData();
      getCurrentUserData();
    });

  const getKycConfig = () =>
    authRequest('business/merchant/kyc/layout', 'GET', {})
      .then((data) => {
        console.log(data);
        setKycConfig(data);
      })
      .catch((err) => {});

  if (business.isKycVerified) {
    navigate('/dashboard/app', { replace: true });
  }

  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
      <Loader loading={isLoading} />
      <KYCNavbar onOpenSidebar={() => setOpen(true)} />
      <KYCSidebar
        isOpenSidebar={open || false}
        onCloseSidebar={() => setOpen(false)}
        kycSectionsStatus={business.kycStatus === 'submitted' ? {} : kycSectionsStatus}
      />
      <MainStyle>
        {business.kycStatus === 'submitted' ? (
          <KYCSubmitted />
        ) : (
          <Outlet
            context={{
              kycConfig,
              businessType,
              setBusinessType,
              generalData,
              setGeneralData,
              bankData,
              setBankData,
              addressData,
              setAddressData,
              kycInfoData,
              kycSectionsStatus,
              setKycInfoData,
              getKycInfoData,
              getBankData,
              getAddressData,
              getGeneralData,
              saveGeneralData,
              saveAddressData,
              saveBankData,
              saveKycInfoData,
            }}
          />
        )}
      </MainStyle>
    </RootStyle>
  );
}
