/* eslint-disable no-nested-ternary */
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Branch from './pages/Branch';
import { useAuth } from './context/auth';
import Profile from './pages/Profile';
import { useGlobal } from './context/global';
import KYCLayout from './layouts/kyc';
import KYC from './pages/kyc/KYC';
import GeneralInformation from './pages/kyc/GeneralInformation';
import BusinessAddress from './pages/kyc/BusinessAddress';
import BankDetails from './pages/kyc/BankDetails';
import KYCSubmitted from './pages/kyc/Submitted';
import ChangePassword from './pages/ChangePassword';

// ----------------------------------------------------------------------

export default function Router() {
  const { isLoggedIn, authData } = useAuth();
  const user = authData?.user;

  const business = user?.businessUser?.business || {};
  let kycVerified = false;
  const passwordReset = user && user.miscJson && user.miscJson.passwordResetDone;
  if (user?.businessUser?.business.isKycVerified) kycVerified = user?.businessUser?.business.isKycVerified;

  const protectRoute = (component) => {
    if (user && !kycVerified) {
      // if (business.kycStatus === 'submitted') {
      //   return <Navigate to="/kycVerification" replace />;
      // }
      // console.log('business kyc', !kycVerified);
      return <Navigate to={passwordReset ? '/kyc/general' : '/change_password'} replace />;
    }
    return isLoggedIn ? component : <Navigate to="/login" />;
  };

  const skipRouteIfLoggedIn = (component) => {
    if (user && !kycVerified) {
      console.log('business kyc', kycVerified);
      // if (business.kycStatus === 'submitted') {
      //   return <Navigate to="/kycVerification" replace />;
      // }
      return <Navigate to={passwordReset ? '/kyc/general' : '/change_password'} replace />;
    }
    return isLoggedIn ? <Navigate to={passwordReset ? '/dashboard/app' : '/change_password'} replace /> : component;
  };

  const checkLoggedIn = (component) =>
    // if (business.kycStatus === 'submitted') {
    //   return <Navigate to="'/kycVerification'" replace />;
    // }
    isLoggedIn ? passwordReset ? component : <Navigate to="change_password" /> : <Navigate to="/login" />;
  return useRoutes([
    {
      path: '/dashboard',
      element: protectRoute(<DashboardLayout />),
      children: [
        { path: 'app', element: <DashboardApp /> },
        // { path: 'branch', element: <Branch /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'profile', element: <Profile /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: skipRouteIfLoggedIn(<Login />) },
        { path: 'register', element: skipRouteIfLoggedIn(<Register />) },
        { path: 'change_password', element: <ChangePassword /> },
        { path: '404', element: <NotFound /> },
        // { path: 'kycVerification', element: <KYCSubmitted/> },
      ],
    },
    {
      path: '/kyc',
      element: checkLoggedIn(<KYCLayout />),
      children: [
        { path: 'general', element: <GeneralInformation /> },
        { path: 'address', element: <BusinessAddress /> },
        { path: 'bank', element: <BankDetails /> },
        { path: 'info', element: <KYC /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
