import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  useScrollTrigger,
} from '@mui/material';
import { formatter } from '../../../utils/helper';
import { useAuth } from '../../../context/auth';
import { fDate, fDateTime } from '../../../utils/formatTime';

const DebitHistoryTable = () => {
  const { authRequest } = useAuth();

  const [debitHistory, setDebitHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const rows = [
    {
      dateTime: '2022-05-14T05:06:29.062Z',
      amount: 1000,
      type: 'Pending',
    },
    {
      dateTime: '2022-05-13T05:06:29.062Z',
      amount: 1000,
      type: 'Debited',
    },
    {
      dateTime: '2022-05-12T03:26:29.062Z',
      amount: 1000,
      type: 'Debited',
    },
  ];
  const columns = ['DateTime', 'Amount', 'Status'];

  const getBusinessDebitHistory = () => {
    setIsLoading(true);
    return authRequest('business/caset/mandate/debit', 'GET', {})
      .then((data) => {
        console.log(data);
        const newRows = [];
        (data || []).forEach((d) => {
          newRows.push({
            dateTime: d.salesday,
            type: 'Debited',
            amount: d.charged,
            total: d.total,
          });
        });
        setDebitHistory(newRows);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getBusinessDebitHistory();
  }, []);

  return (
    <TableContainer sx={{ minWidth: 400 }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell key={i}>{col}</TableCell>
            ))}

            {/* <TableCell>
              
          </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(debitHistory || []).map((row) => (
            <TableRow key={row.dateTime}>
              <TableCell>{fDateTime(row.dateTime)}</TableCell>
              <TableCell>{formatter.format(row.amount)}</TableCell>
              <TableCell>
                {row.type}
                {/* <Checkbox checked={row.type} disabled /> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DebitHistoryTable.propTypes = {
  //   columns: PropTypes.object,
  rows: PropTypes.array,
};

export default DebitHistoryTable;
