import { Box, Button, Card, Container, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/auth';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';

const BankDetails = () => {
  const { authData } = useAuth();
  const {
    kycConfig: config,
    businessType,
    setBusinessType,
    bankData: bd,
    saveBankData,
    kycSectionsStatus,
  } = useOutletContext();
  const [bankData, setBankData] = useState({
    upiAddress: null,
    bankAccount: null,
    confirmBankAccount: '',
    ifsc: null,
    accountHolderName: '',
  });

  const sectionStatus = kycSectionsStatus.bank_verification;

  useEffect(() => {
    setBankData({
      ...bd,
      confirmBankAccount: bd.bankAccount,
    });
  }, [bd]);

  const handleChange = (k, v) => {
    setBankData({
      ...bankData,
      [k]: v,
    });
  };

  const user = (authData && authData?.user) || {};
  return (
    <Page title="BankDetails">
      <Container>
        <Stack spacing={2} paddingTop={2}>
          <Stack spacing={1} paddingTop={2}>
            <Typography variant="h3">Bank Details</Typography>
            <Typography>We need your bank account details to process settlements.</Typography>
          </Stack>

          {sectionStatus && sectionStatus.status === 'rejected' ? (
            <Stack>
              <Typography color={'red'}>{sectionStatus.reason}</Typography>
            </Stack>
          ) : null}
          <Card>
            <Stack padding={2} spacing={2}>
              <Box flexGrow={1}>
                <Typography>Account Number</Typography>
                <TextField
                  value={bankData.bankAccount || ''}
                  onChange={(e) => {
                    handleChange('bankAccount', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <Typography>Confirm Account Number</Typography>
                <TextField
                  value={bankData.confirmBankAccount || ''}
                  error={bankData.confirmBankAccount !== bankData.bankAccount}
                  helperText={bankData.confirmBankAccount !== bankData.bankAccount ? 'Not matching' : ''}
                  onChange={(e) => {
                    handleChange('confirmBankAccount', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <Typography>Account Holder Name</Typography>
                <TextField
                  value={bankData.accountHolderName || ''}
                  onChange={(e) => {
                    handleChange('accountHolderName', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <Typography>IFSC</Typography>
                <TextField
                  value={bankData.ifsc || ''}
                  onChange={(e) => {
                    handleChange('ifsc', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </Stack>
          </Card>
          <Button
            variant={'contained'}
            onClick={() => {
              console.log(bankData);
              if (bankData.bankAccount !== bankData.confirmBankAccount) {
                return;
              }
              saveBankData(bankData)
                .then(() => {
                  toast.success('Updated!');
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            }}
          >
            Save & Continue
          </Button>
        </Stack>
      </Container>
    </Page>
  );
};

export default BankDetails;
