import { faker } from '@faker-js/faker';
import * as Yup from 'yup';
// @mui
import React, { useState, useEffect, Fragment } from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Stack,
  Button,
  Stepper,
  Step,
  StepButton,
  Box,
  StepLabel,
  StepContent,
  Link,
  Modal,
  FormLabel,
  Input,
  TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { LoadingButton } from '@mui/lab';

import toast from 'react-hot-toast';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  BranchesTable,
  CasetsTable,
  DebitHistoryTable,
  PreSaleRevenue,
} from '../sections/@dashboard/app';
import { useAuth } from '../context/auth';
import { hasRole } from '../utils/helper';
import { ROLE_MAP } from '../utils/const';
import Scrollbar from '../components/Scrollbar';

// ----------------------------------------------------------------------
const verticalSteps = [2, 2, 2, 1, 2];

export default function DashboardApp() {
  const { roles, authRequest } = useAuth();
  const [openSellIncomeRequestModal, setOpenSellIncomeRequestModal] = useState(false);
  const [statsInfo, setStatsInfo] = useState({
    total_amount: 0,
    total_items: 0,
    total_customers: 0,
  });
  const [branchesData, setBranchesData] = useState([]);
  const [casetsData, setCasets] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);
  const [activeStep, setActiveStep] = useState(-1);
  const theme = useTheme();

  const [currentProgressCaset, setCurrentProgressCaset] = useState(null);
  const [casetRequest, setCasetRequest] = useState(null);
  const [currentCaset, setCurrentCaset] = useState(null);
  const [currentCasetSales, setCurrentCasetSales] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const steps = ['Request', 'Accepted', 'Verified', 'Listed', 'Live'];

  const [stepStatus, setStepStatus] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  // const stepStatus = {};

  const CasetRequestSchema = Yup.object().shape({
    requestPercent: Yup.number().positive().min(1).max(100),
    requestAmount: Yup.number().min(1).positive(),
    requestTenure: Yup.number().integer().min(1).positive().max(12),
  });

  const getBranches = () => {
    authRequest('business/branches', 'GET', {})
      .then((data) => {
        setBranchesData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBusinessCurrentCaset = () => {
    setIsLoading(true);
    return authRequest('business/caset/current', 'GET', {})
      .then((data) => {
        setCurrentProgressCaset(data);
        if (!(data && data.approvalSteps && data.approvalSteps.length)) {
          return;
        }
        const { approvalSteps } = data;
        let lastIndex = 0;
        const completedStatus = {};
        // const steps = approvalSteps.slice(0, 2);
        verticalSteps.forEach((v, i) => {
          const steps = approvalSteps.slice(lastIndex, lastIndex + v);
          let completed = true;

          steps.forEach((s) => {
            if (s.status !== 'completed') {
              completed = false;
            }
          });

          completedStatus[i] = completed;
          lastIndex += v;
        });
        setStepStatus(completedStatus);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getBusinessCurrentCasetSales = () => {
    setIsLoading(true);
    return authRequest('business/caset/current/sales', 'GET', {})
      .then((data) => {
        setCurrentCasetSales(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getBusinessKycConfig = () => {
    authRequest('business/kyc/config', 'GET', {})
      .then((data) => {
        console.log('KYC config', data);
        console.log(JSON.stringify(data));
        // setStatsInfo(d);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    authRequest('business/stats', 'GET', {})
      .then((data) => {
        const d = {
          total_amount: 0,
          total_items: 0,
          total_customers: 0,
        };
        (data || []).forEach((i) => {
          d.total_amount += i.total_amount || 0;
          d.total_items += i.total_items || 0;
          d.total_customers += i.total_customers || 0;
        });
        setStatsInfo(d);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get branches
    if (hasRole(roles, ROLE_MAP.BUSINESS_SUPER_ADMIN)) getBranches();

    // Get Casets
    authRequest('business/casets', 'GET', {})
      .then((data) => {
        setCasets(data);
      })
      .catch((err) => {
        console.log(err);
      });

    // getBusinessKycConfig();

    getBusinessCurrentCaset();
    getBusinessCurrentCasetSales();
  }, []);

  useEffect(() => {
    setActiveStep(-1);
  }, [currentStep]);

  const formik = useFormik({
    initialValues: {
      requestPercent: '',
      requestAmount: '',
      requestTenure: '',
    },
    validationSchema: CasetRequestSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);
      authRequest('caset/request', 'POST', values)
        .then((data) => {
          formik.setSubmitting(false);
          formik.resetForm();
          setOpenSellIncomeRequestModal(false);

          toast.success('Success');
        })
        .catch((err) => {
          formik.setSubmitting(false);
          toast.error(err.message);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const renderVerticalStepper = (steps) => (
    <Stepper activeStep={activeStep} nonLinear orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.id} completed={step.status === 'completed'}>
          <StepLabel
            StepIconComponent={
              step.stepIcon ||
              (step.notCompleted
                ? () => <Iconify sx={{ color: '#ddd', width: 32, height: 32 }} icon={'akar-icons:circle-fill'} />
                : undefined)
            }
            onClick={() => {
              if (index === activeStep) return setActiveStep(-1);
              setActiveStep(index);
            }}
            optional={index === (steps || []).length - 1 ? <Typography variant="caption">Last step</Typography> : null}
          >
            {/* <Typography variant="h6"> */}
            {step.name}
            {/* </Typography> */}
          </StepLabel>
          {/* <StepLabel></StepLabel> */}
          <StepContent>
            <Typography>{step.description}</Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );

  const renderStepData = () => {
    if (!(currentProgressCaset && currentProgressCaset.approvalSteps && currentProgressCaset.approvalSteps.length)) {
      return <></>;
    }
    const { approvalSteps } = currentProgressCaset;
    if (currentStep === 0) {
      const steps = approvalSteps.slice(0, 2);
      return renderVerticalStepper(steps);
    }
    if (currentStep === 1) {
      const stepsData = approvalSteps.slice(2, 4);
      const steps = [
        stepsData[0],
        {
          name: stepsData[1].name,
          status: stepsData[1].status,
          description:
            stepsData[1].extraJson && stepsData[1].extraJson.file ? (
              <Typography>
                <Link target={'_blank'} referrerPolicy="no-referrer" href={stepsData[1].extraJson.file.fileUrl}>
                  Agreement
                </Link>{' '}
                Signed
              </Typography>
            ) : (
              <Typography>Agreement</Typography>
            ),
        },
      ];
      return renderVerticalStepper(steps);
    }
    if (currentStep === 2) {
      const steps = approvalSteps.slice(4, 6);
      // const steps = [
      //   {
      //     label: 'Payment Data',
      //     description: 'Verified Account details',
      //   },
      //   {
      //     label: 'Added to POS',
      //     description: 'Verified integration with POS',
      //   },
      // ];
      return renderVerticalStepper(steps);
    }
    if (currentStep === 3) {
      const steps = approvalSteps.slice(6, 7);
      // const steps = [
      //   {
      //     label: 'Caset Listed',
      //     description: 'Caset listed completed',
      //   },
      // ];
      return renderVerticalStepper(steps);
    }
    if (currentStep === 4) {
      const stepsData = approvalSteps.slice(7);
      const steps = [
        {
          id: stepsData[0].id,
          name: `Went Live ${new Date(currentProgressCaset.startDate).toLocaleDateString()}`,
          description: 'Caset went live',
          status: stepsData[0].status,
        },
        {
          id: 2,
          name: `${currentProgressCaset.validity} months`,
          description: '',
          stepIcon: () => (
            <Iconify
              sx={{ color: '#c4cdd5', width: 25, height: 30, transform: 'scaleX(0.55) scaleY(2.8)' }}
              icon="ci:line-l"
            />
          ),
        },
        {
          id: stepsData[1].id,
          name: `Ends on ${new Date(currentProgressCaset.endDate).toLocaleDateString()}`,
          status: stepsData[1].status,
        },
      ];
      return renderVerticalStepper(steps);
    }
    return <Typography sx={{ mt: 2, mb: 1 }}>Step {currentStep + 1}</Typography>;
  };
  return (
    <Page title="Dashboard">
      <Modal
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        open={openSellIncomeRequestModal}
      >
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{
              maxWidth: '100vw',
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              sx={{
                minHeight: 200,
                minWidth: 400,
                padding: 2,
              }}
            >
              <div
                onClick={() => {
                  setOpenSellIncomeRequestModal(false);
                }}
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                }}
              >
                <Iconify icon={'carbon:close-filled'} width={30} height={30} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, gap: '1rem' }}>
                <div>
                  {/* <FormLabel>Request Percent</FormLabel> */}
                  {/* <Input />  */}
                  <TextField
                    label="Request Conversion Percent"
                    type={'number'}
                    variant="outlined"
                    {...getFieldProps('requestPercent')}
                    fullWidth
                    error={Boolean(touched.requestPercent && errors.requestPercent)}
                    helperText={touched.requestPercent && errors.requestPercent}
                  />
                </div>
                <div>
                  <TextField
                    label="Percent proposed amount"
                    variant="outlined"
                    {...getFieldProps('requestAmount')}
                    type={'number'}
                    fullWidth
                    error={Boolean(touched.requestAmount && errors.requestAmount)}
                    helperText={touched.requestAmount && errors.requestAmount}
                  />
                </div>
                <div>
                  <TextField
                    label="Months"
                    variant="outlined"
                    type={'number'}
                    {...getFieldProps('requestTenure')}
                    fullWidth
                    error={Boolean(touched.requestTenure && errors.requestTenure)}
                    helperText={touched.requestTenure && errors.requestTenure}
                  />
                </div>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    // setOpenSellIncomeRequestModal(false);
                  }}
                >
                  Send Request
                </LoadingButton>
              </div>
            </Card>
          </Form>
        </FormikProvider>
      </Modal>
      <div style={{ position: 'fixed', bottom: '10px', zIndex: 10, right: 36 }}>
        <Button variant="contained" startIcon={<Iconify icon="carbon:phone-filled" />}>
          Contact Us for support
        </Button>
      </div>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <div />
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  setOpenSellIncomeRequestModal(true);
                }}
              >
                Sell your incoming revenue
              </Button>
            </Stack>
          </Grid>

          {(currentProgressCaset && currentProgressCaset.approvalSteps) || currentCasetSales ? null : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="h5">Start Selling your revenue</Typography>
            </Grid>
          )}

          {currentProgressCaset && currentProgressCaset.approvalSteps ? (
            <>
              {' '}
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5">Caset Listing Progress</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding={2}>
                {/* <Scrollbar sx={{min}}> */}
                <Stepper nonLinear activeStep={currentStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={stepStatus[index] || false}>
                      <StepButton
                        color="inherit"
                        onClick={() => {
                          if (index === currentStep) {
                            return setCurrentStep(-1);
                          }
                          return setCurrentStep(index);
                        }}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                {/* </Scrollbar> */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {currentStep > -1 ? (
                  <Card>
                    <Stack padding={2}>{renderStepData()}</Stack>
                  </Card>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          ) : null}

          {currentCasetSales ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h5">Pre Revenue Sales</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <PreSaleRevenue data={currentCasetSales} />
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Sales Forecasting"
              // subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2022',
                '02/01/2022',
                '03/01/2022',
                '04/01/2022',
                '05/01/2022',
                '06/01/2022',
                '07/01/2022',
                '08/01/2022',
                '09/01/2022',
                '10/01/2022',
                '11/01/2022',
              ]}
              chartData={[
                {
                  name: 'Actual Sales',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Forecasted Sales',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            {/* <Typography variant="h5">Pre Selling Revenue</Typography> */}
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h5">Debit History</Typography>
            <DebitHistoryTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
