import React, { createContext, useState, useContext, useEffect, VoidFunctionComponent } from 'react';
// import { useGlobal } from '../global/global';
// import { BASE_URL } from '../../util/const';
import { BASE_URL } from '../utils/const';
import { storeData, getStoredData } from '../utils/storage';
import { useGlobal } from './global';
// import {AuthData, authService} from '../services/authService';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState();
  const [roles, setRoles] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { updateGlobalState } = useGlobal();

  //    The loading part will be explained in the persist step session
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    function startSetup() {
      console.log('\n\n\n\n\n\n\nStartup setup running!\n\n\n\n\n\n\n\n');
      const token = getStoredData('access_token');

      if (!token) {
        console.log('Token not found');
        return;
      }
      console.log('Token found');
      const user = getStoredData('user');

      //   let longitude = await getStoredData('longitude');
      //   let latitude = await getStoredData('latitude');
      //   if (longitude && latitude) {
      //     updateGlobalState('coordinates', {
      //       latitude: latitude,
      //       longitude: longitude,
      //     });
      //   }
      setAuthData({
        access: token,
        user,
      });

      updateGlobalState('user', user);
      if (user && token) {
        setIsLoggedIn(true);
        getMe();
      }
    }
    startSetup();

    // if(getStoredData('access_token')){
    //   authR
    // }
  }, []);

  useEffect(() => {
    if (authData && authData.user) {
      setRoles((authData.user.role || []).map((item) => item.key));
    }
  }, [authData]);

  useEffect(() => {
    // console.log('roles :', roles);
  }, [roles]);
  //   async function requestValidation(resp) {
  //     return new Promise(async (s, f) => {
  //       if (resp.status === 401) {
  //         signOut();
  //         s();
  //         // return null;
  //       }
  //       //   const res = await resp.json();

  //       resp.json().then((res) => {
  //         if (resp.status === 405) {
  //           console.log(res);
  //           return f({
  //             message: 'Invalid Method',
  //           });
  //         }
  //         if (resp.status > 399) {
  //           console.log(res);
  //           return f(res);
  //         }
  //         s(res);
  //       });
  //     });
  //   }
  async function requestValidation(resp) {
    if (resp.status === 401) {
      signOut();
      return null;
    }
    const res = await resp.json();
    if (resp.status === 405) {
      console.log(res);
      throw new Error('Invalid Method');
    }
    if (resp.status > 399) {
      console.log(res);
      if (res.message) {
        if (Array.isArray(res.message)) {
          throw new Error(res.message.join('\n'));
        } else {
          throw new Error(res.message);
        }
      } else {
        throw new Error('failed');
      }
    }
    return res;
  }

  async function request(URL, method, data, isAuthNeeded = false, formData = false) {
    const token = getStoredData('access_token');
    let body = JSON.stringify(undefined);
    if (method !== 'GET') {
      body = formData ? data : JSON.stringify(data || {});
    }
    if (isAuthNeeded) {
      // Auth required request

      if (formData) {
        return fetch(BASE_URL + URL, {
          method,
          body,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }).then((resp) => requestValidation(resp));
      }
      return fetch(BASE_URL + URL, {
        method,
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((resp) => requestValidation(resp));
    }

    // Not auth request
    if (formData) {
      return fetch(BASE_URL + URL, {
        method,
        body,
        headers: {
          Accept: 'application/json',
        },
      }).then((resp) => requestValidation(resp));
    }
    return fetch(BASE_URL + URL, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((resp) => requestValidation(resp));
  }

  function authRequest(URL, method, data) {
    return request(URL, method, data, true);
  }

  function formAuthRequest(URL, method, data) {
    return request(URL, method, data, true, true);
  }

  function formRequest(URL, method, data) {
    return request(URL, method, data, false, true);
  }

  function fileRequest(URL) {
    const token = getStoredData('access_token');

    return fetch(BASE_URL + URL, {
      method: 'GET',
      body: undefined,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((resp) => resp.blob());
  }

  const signIn = async (data, authenticated = false) => {
    if (data.token) {
      storeData('access_token', data.token);
      storeData('user', data.user);
      updateGlobalState('user', data.user);
      setIsLoggedIn(true);
    }

    setAuthData(data);
    if (authenticated) {
      setIsLoggedIn(true);
    }
  };

  const setKycVerified = () => {
    const a = { ...authData };
    a.user.businessUser.business.isKycVerified = true;
    setAuthData(a);
  };

  const updateUserData = async (data) => {
    if (authData) {
      const a = { ...authData };
      a.user = data;
      setAuthData(a);
      storeData('user', data);
      updateGlobalState('user', data);
    }
  };

  const getMe = () =>
    // setIsLoading(true);
    authRequest('business/user/me', 'GET', {})
      .then((data) => {
        console.log(data);
        // setIsLoading(false);
        // console.log
        updateUserData(data);
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
        // showToast(err.message || 'Error');
      });
  useEffect(() => {
    if (!isLoggedIn) {
      updateGlobalState('user', null);
    }
  }, [isLoggedIn]);

  const signOut = async () => {
    console.log('Signed Out');
    setAuthData(undefined);
    storeData('access_token', null);
    storeData('user', null);
    setIsLoggedIn(false);
  };

  return (
    // This component will be used to encapsulate the whole App,
    // so all components will have access to the Context
    <AuthContext.Provider
      value={{
        authData,
        loading,
        signIn,
        signOut,
        isLoggedIn,
        request,
        authRequest,
        formAuthRequest,
        formRequest,
        updateUserData,
        roles,
        setKycVerified,
        fileRequest,
        getMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

// export const AuthProvider = _AuthProvider;
export { AuthProvider, AuthContext, useAuth };
