import React, { createContext, useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({});

  useEffect(() => {}, []);

  const updateGlobalState = (key, value) => {
    const currentState = { ...(globalState || {}) };
    currentState[key] = value;
    setGlobalState(currentState);
  };

  const showToast = (message) => {
    toast.success(message);
  };

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        updateGlobalState,
        showToast,
      }}
    >
      {/* <Spinner animating={Boolean(requestsFetching)} /> */}
      {children}
    </GlobalContext.Provider>
  );
};

function useGlobal() {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobal must be used within an GlobalProvider');
  }

  return context;
}

export { GlobalProvider, GlobalContext, useGlobal };
