function hasRole(currentUserRoles, checkRoles) {
  if (!Array.isArray(checkRoles)) {
    checkRoles = [checkRoles];
  }

  let isValid = false;
  (currentUserRoles || []).forEach((r) => {
    if (checkRoles.indexOf(r) !== -1) {
      isValid = true;
    }
  });
  return isValid;
}

const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',

  // These options are needed to round to whole numbers if that's what you want.
  
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export { hasRole, formatter };
