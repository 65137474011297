import React from 'react';
import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from './Iconify';

const getIcon = (name) => <Iconify icon={name} width={50} height={50} />;

// eos-icons:loading
const Loader = ({ loading }) => (
  <Modal
    BackdropProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        background: 'rgba(0, 0, 0, 0.5)',
      },
    }}
    open={Boolean(loading)}
  >
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        color: 'white',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {getIcon('eos-icons:loading')}
    </div>
  </Modal>
);
Loader.propTypes = {
  loading: PropTypes.bool,
};

export default Loader;
