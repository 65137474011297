import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/auth';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';

const BusinessAddress = () => {
  const { kycConfig: config, addressData: ad, saveAddressData, kycSectionsStatus } = useOutletContext();
  const kycConfig = config || {};
  const sectionStatus = kycSectionsStatus.address_information;

  const { authData } = useAuth();
  const user = (authData && authData?.user) || {};
  const [addressData, setAddressData] = useState({
    address: {
      address: '',
      pincode: '',
      city: '',
      state: '',
    },
    gstAddress: {
      address: '',
      pincode: '',
      city: '',
      state: '',
    },
    isGstAddressSame: false,
  });

  useEffect(() => {
    setAddressData(ad);
  }, [ad]);

  // const handleChange = (key, value) => {
  //   const newData = { ...addressData };
  //   newData[key] = value;
  //   // console.log(key, value, newData);
  //   setAddressData(newData);
  // };

  const handleChange = (f, key, value) => {
    const updated = { ...addressData };
    updated[f][key] = value;
    setAddressData(updated);
  };

  return (
    <Page title="BusinessAddress">
      <Container>
        <Stack spacing={2} paddingTop={2}>
          <Stack spacing={1} paddingTop={2}>
            <Typography variant="h3">Business Address</Typography>
            <Typography>We need your registered business address for offline communication.</Typography>
          </Stack>
          {sectionStatus && sectionStatus.status === 'rejected' ? (
            <Stack>
              <Typography color={'red'}>{sectionStatus.reason}</Typography>
            </Stack>
          ) : null}
          <Card>
            <Stack padding={2} spacing={2}>
              <Box flexGrow={1}>
                <Typography>Address</Typography>
                <TextField
                  value={addressData.address.address || ''}
                  onChange={(e) => {
                    handleChange('address', 'address', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <Typography>Pincode</Typography>
                <TextField
                  value={addressData.address.pincode || ''}
                  onChange={(e) => {
                    handleChange('address', 'pincode', e.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <Typography>City</Typography>
                <TextField
                  value={addressData.address.city || ''}
                  onChange={(e) => {
                    handleChange('address', 'city', e.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box flexGrow={1}>
                <FormControl fullWidth>
                  <Typography>State</Typography>
                  <Select
                    value={addressData.address.state || ''}
                    onChange={(e) => {
                      handleChange('address', 'state', e.target.value);
                    }}
                  >
                    {(kycConfig.stateOptions || []).map((v, i) => {
                      return (
                        <MenuItem key={v.key || i} value={v.key}>
                          {v.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Card>
          {!addressData.isGstAddressSame && (
            <Stack spacing={2} paddingTop={2}>
              <Stack spacing={1} paddingTop={2}>
                <Typography variant="h3">GST Address</Typography>
                <Typography>Address should be as per the GST documents.</Typography>
              </Stack>
              <Card>
                <Stack padding={2} spacing={2}>
                  <Box flexGrow={1}>
                    <Typography>Address</Typography>
                    <TextField
                      value={addressData.gstAddress.address || ''}
                      onChange={(e) => {
                        handleChange('gstAddress', 'address', e.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Pincode</Typography>
                    <TextField
                      value={addressData.gstAddress.pincode || ''}
                      onChange={(e) => {
                        handleChange('gstAddress', 'pincode', e.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>City</Typography>
                    <TextField
                      value={addressData.gstAddress.city || ''}
                      onChange={(e) => {
                        handleChange('gstAddress', 'city', e.target.value);
                      }}
                      fullWidth
                    />
                  </Box>

                  <Box flexGrow={1}>
                    <FormControl fullWidth>
                      <Typography>State</Typography>
                      <Select
                        value={addressData.gstAddress.state || ''}
                        onChange={(e) => {
                          handleChange('gstAddress', 'state', e.target.value);
                        }}
                      >
                        {(kycConfig.stateOptions || []).map((v, i) => {
                          return (
                            <MenuItem key={v.key || i} value={v.key}>
                              {v.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Card>
            </Stack>
          )}
          <Box display={'flex'} flexDirection={'row'}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    console.log(e.target.checked);
                    const changedData = { ...addressData };
                    setAddressData({
                      ...addressData,
                      isGstAddressSame: e.target.checked,
                    });
                  }}
                  checked={addressData.isGstAddressSame}
                />
              }
              label="GST Address is same as above address"
            />
          </Box>
          <Button
            variant={'contained'}
            onClick={() => {
              const saveData = { ...addressData };
              if (addressData.isGstAddressSame) {
                saveData.gstAddress = { ...saveData.address };
              }
              saveAddressData(saveData)
                .then(() => {
                  toast.success('Saved Successfully');
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            }}
          >
            Save & Continue
          </Button>
        </Stack>
      </Container>
    </Page>
  );
};

export default BusinessAddress;
