import { Box, Button, Card, Container, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useGlobal } from '../context/global';

import { useAuth } from '../context/auth';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Loader from '../components/Loader';

const Profile = () => {
  const { authData, getMe, authRequest } = useAuth();
  const { showToast } = useGlobal();
  const user = authData.user || {};
  const wallet = user.wallet || {};
  const [isLoading, setIsLoading] = useState(false);

  const [personalInformation, setPersonalInformation] = useState({
    firstName: '',
    lastName: '',
  });
  const [bankDetails, setBankDetails] = useState({
    bankAccount: '',
    ifsc: '',
    upiAddress: '',
  });

  const handlePersonalChange = (key, value) => {
    setPersonalInformation({ ...personalInformation, [key]: value });
  };

  const handleBankDataChange = (key, value) => {
    setBankDetails({ ...bankDetails, [key]: value });
  };

  useEffect(() => {
    if (wallet)
      setBankDetails({
        bankAccount: wallet.bankAccount || '',
        ifsc: wallet.ifsc || '',
        upiAddress: wallet.upiAddress || '',
      });

    if (user)
      setPersonalInformation({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
      });
  }, [wallet, user]);

  const savePersonalDetails = () => {
    setIsLoading(true);
    authRequest('business/user/information', 'PATCH', personalInformation)
      .then(async (data) => {
        console.log(data);
        await getMe();
        setIsLoading(false);
        // console.log
        // updateUserData(data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error(err.message || 'Error');
      });
  };

  const saveAndVerifyPaymentDetails = () => {
    setIsLoading(true);
    return authRequest('user/payment_details/verify', 'POST', bankDetails)
      .then(async () => {
        setIsLoading(false);
        await getMe();
      })
      .catch(async (err) => {
        setIsLoading(false);
        // await getMe();

        toast.error(err.message || 'Error');
      });
  };

  return (
    <>
      <Loader loading={isLoading} />

      <Page title="Profile">
        <Container>
          <Stack spacing={2} paddingTop={2}>
            <Typography variant="h5">Personal Information</Typography>
            <Card>
              <Stack padding={2} spacing={2}>
                {/* <Typography variant="h5">Personal Information</Typography> */}
                <Stack direction={'row'} spacing={2}>
                  <TextField
                    fullWidth
                    label={'First Name'}
                    value={personalInformation.firstName}
                    onChange={(e) => {
                      handlePersonalChange('firstName', e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth
                    label={'Last Name'}
                    value={personalInformation.lastName}
                    onChange={(e) => {
                      handlePersonalChange('lastName', e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                  <TextField fullWidth label={'Email'} disabled value={user.email} />
                  <TextField fullWidth label={'Phone Number'} disabled value={user.phoneNumber} />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                  <Button variant={'contained'} fullWidth onClick={savePersonalDetails}>
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Stack>

          <Stack spacing={2} paddingTop={2}>
            <Stack direction={'row'}>
              <Box display={'flex'}>
                <Typography flexGrow={1} variant="h5">
                  Payment Details
                </Typography>
                <Box alignSelf={'center'} style={{ fontSize: 20, color: 'green', paddingLeft: 2 }}>
                  {/* <Iconify icon={'akar-icons:circle-check-fill'} /> */}
                </Box>
              </Box>

              {/* <Typography variant="h5">
              <Iconify icon={'akar-icons:circle-check-fill'} />
            </Typography> */}
            </Stack>
            <Card>
              <Stack padding={2} spacing={2}>
                {/* <Typography variant="h5">Personal Information</Typography> */}
                <Stack direction={'row'} spacing={2}>
                  <TextField
                    fullWidth
                    label={'Bank Account'}
                    value={bankDetails.bankAccount}
                    onChange={(e) => {
                      handleBankDataChange('bankAccount', e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth
                    label={'IFSC code'}
                    value={bankDetails.ifsc}
                    onChange={(e) => {
                      handleBankDataChange('ifsc', e.target.value);
                    }}
                  />
                </Stack>
                {/* <Stack direction={'row'} spacing={2}>
                  <TextField
                    fullWidth
                    label={'UPI'}
                    value={bankDetails.upiAddress}
                    onChange={(e) => {
                      handleBankDataChange('upiAddress', e.target.value);
                    }}
                  />
                </Stack> */}
                <Stack direction={'row'} spacing={2}>
                  <Button variant={'contained'} fullWidth onClick={saveAndVerifyPaymentDetails}>
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Profile;
