// const BASE_URL = 'http://localhost:81/';
// const BASE_URL = 'https://c583-2401-4900-1c0e-7400-c8cb-14ac-c425-f0f4.ngrok.io/';
// const BASE_URL = 'https://devapi.caset.in/';
const BASE_URL = 'https://api.caset.in/';
// const BASE_URL = 'http://192.168.1.11:81/';

const ROLE_MAP = {
  SUPER_ADMIN: 'super_admin',
  USER: 'user',
  BUSINESS_SUPER_ADMIN: 'business_super_admin',
  BUSINESS_BRANCH_ADMIN: 'business_branch_admin',
};
export { BASE_URL, ROLE_MAP };
