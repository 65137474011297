import React, { useEffect } from 'react';
import Page from '../components/Page';

const Branch = () => {
  return (
    <Page>
      <div>Branch</div>
    </Page>
  );
};

export default Branch;
